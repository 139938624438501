import Popup from "reactjs-popup";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import "./branch_detail.scss";

export const BranchDetailPopup = ({ data, show, onSubmit, onClose }) => {
  const [branchName, setBranchName] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [branchPhone, setBranchPhone] = useState("");
  const user = useSelector(selectCurrentUser);

  const handleBranchNameInput = (e) => {
    setBranchName(e.target.value);
  };
  const handleBranchAddressInput = (e) => {
    setBranchAddress(e.target.value);
  };
  const handleBranchPhoneInput = (e) => {
    setBranchPhone(e.target.value);
  };


  const submitHandle = () => {
    onSubmit();
    onClose();
    setBranchName("");
    setBranchAddress("")
    setBranchPhone("")
  };


  return (
    <div>
      <Popup
        modal
        className="branch-detail-popup"
        open={show}
        onClose={() => {
          onClose();
          setBranchName("");
          setBranchAddress("")
          setBranchPhone("")
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
            setBranchName("");
            setBranchAddress("")
            setBranchPhone("")
          }}
        >
          x
        </button>
        <div className="branch-detail-outer">
          <div className="popup-heading">Branch Details</div>
          <div className="popup-content-inner">
          {/* <div className="branch-name-outer">
              <div className="name-label">
                ID 
              </div>
              <div>{data.id}</div>
            </div> */}
            <div className="branch-name-outer">
              <div className="name-label">
                Name 
              </div>
              <div>{data.branch_name_nn}</div>
            </div>
            <div className="branch-name-outer">
              <div className="name-label">
                Address 
              </div>
              <div>{data.branch_address}</div>
            </div>
            <div className="branch-name-outer">
              <div className="name-label">
                Phone Number 
              </div>
              <div>{data.branch_phone}</div>
            </div>
          </div>
          
          <div className="popup-content-functionality-btn">
            <button
              className={"cancel-btn"}
              onClick={submitHandle}
            >
              Delete
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
