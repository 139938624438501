let DEBUG = false;
let HOST_URL = "https://dev.antrocorp.com";
let SOCKET_URL_CHAT = "wss://dev.antrocorp.com";
let SOCKET_URL_GROUP = "wss://dev.antrocorp.com/ws/broadcast"
if (DEBUG) {
  HOST_URL = "https://dev.antrocorp.com";
  SOCKET_URL_CHAT = "wss://dev.antrocorp.com";
  SOCKET_URL_GROUP = "wss://dev.antrocorp.com/ws/broadcast";
}

export { HOST_URL, SOCKET_URL_CHAT,  SOCKET_URL_GROUP};
