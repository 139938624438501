import { Outlet, NavLink } from "react-router-dom"
import React from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import antrologo from '../../../assets/img/antro-logo.png'
import { SocialIcon } from 'react-social-icons';
import { FaGooglePlay } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";

import './styles.scss'

const Layout1 = () => {

    return (
        <main className="App">
            <Navbar className="outer-landing-navbar">
                <Container className="landing-navbar">
                    {/* <Navbar.Brand href="#home">
                       <img  className="logo-image" />
                    </Navbar.Brand> */}
                     <Navbar.Brand>
                       <img src={antrologo} className="logo-image" alt="Antro Logo" />
                    </Navbar.Brand>
                    <div>
                        <NavLink
                            to="/"
                            style={({ isActive }) => {
                                return isActive ? { color: "plum" } : {};
                            }}
                        >
                            {/* <img src={antrologo} /> */}
                            {/* Home */}
                        </NavLink>

                    </div>
                    <NavLink to="/login">
                        <button className="login-btn">Sign In</button>
                    </NavLink>
                </Container>
            </Navbar>
            <Outlet />
            <footer className="footer">
                <div className="footer-section-1">
                    <img className="section-1-logo" src={antrologo} />
                </div>
                <div className="footer-section-2">
                    <div className="middle-1">
                        {/* <div className="text1">Download Now</div> */}
                        <div className="btns-outer">
                            <button className="download-btn">
                                <div className="btn-image">
                                    <FaGooglePlay />
                                </div>
                                <div className="button-text-outer">
                                    <div className="text-1">Get it on</div>
                                    <div className="text-2">Google Play</div>
                                </div>

                            </button>
                            <button className="download-btn" style={{ marginLeft: "20px" }}>
                                <div className="btn-image">
                                    <FaApple />
                                </div>
                                <div className="button-text-outer">
                                    <div className="text-1">Get it on</div>
                                    <div className="text-2">App Store</div>
                                </div>

                            </button>
                        </div>
                    </div>
                    <div className="middle-2">
                        <div className="link-tag">Home</div>
                        <div className="link-tag">About Us</div>
                        <div className="link-tag">features</div>
                        <div className="link-tag">How it Workds</div>
                        <div className="link-tag">FAQs</div>
                        <div className="link-tag">Contact Us</div>

                    </div>
                    <div className="middle-3">
                        <div className="text-1">Follow us on</div>
                        <div className="social-links">
                        <SocialIcon className="social-icon" url="https://twitter.com/antrocorp" />
                    <SocialIcon className="social-icon" url="https://facebook.com/antrocorp" />
                    <SocialIcon className="social-icon" url="https://instagram.com/antrocorp" />
                    <SocialIcon className="social-icon" url="https://gmail.com/antrocorp" />
                        </div>
                    </div>
                </div>
                <div className="footer-section-3">

                </div>
                <div className="footer-section-4">
                            <div>© Antrocorp</div>
                            <div><a href="https://antrocorp.com/privacy_policy.html">Privacy Policy</a> | <a href="https://antrocorp.com/privacy_policy.html">Terms & conditions</a></div>
                </div>
            </footer>
        </main>
    )
}

export default Layout1
