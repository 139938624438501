import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/accounts/get_user_data/",
      keepUnusedDataFor: 0,
    }),
    getOrganisationMembers: builder.query({
      query: ({ search, sort_by }) => {
        let url = "/organisation/organisation_members/?";
        if (search !== "") {
          url += `search_keyword=${search}&`;
        }
        console.log(sort_by, "asg");
        if (sort_by !== "" && sort_by !== undefined) {
          url += `sort_by=${sort_by}&`;
        }
        return url
      },
      keepUnusedDataFor: 0,
    }),
    getFailedUploadMembers: builder.query({
      query: () => "/organisation/failed_member_uploads/",
      keepUnusedDataFor: 0,
    }),
    getDashboardData: builder.query({
      query: () => "/organisation/dashboard_data/",
      keepUnusedDataFor: 0,
    }),
    getUserProfile: builder.query({
      query: () => "/accounts/profile/",
      keepUnusedDataFor: 0,
    }),
    getOrganisationLocations: builder.query({
      query: ({ parent_id }) => {
        if (parent_id !== null) {
          return `/organisation/locations/?parent_id=${parent_id}/`;
        } else {
          return `/organisation/locations/`;
        }
      },
      keepUnusedDataFor: 0,
    }),
    getOrganisationLocationBranches: builder.query({
      query: ({ parent_id }) => `/organisation/location_branches/${parent_id}/`,
      keepUnusedDataFor: 0
    }),
    getChannelDetails: builder.query({
      query: ({ group_id }) => `/organisation/group_details/${group_id}/`,
      keepUnusedDataFor: 0
    }),
    getUserDetails: builder.query({
      query: ({ user_id }) => `/accounts/get_user_data/${user_id}/`,
      keepUnusedDataFor: 0
    }),
    getMyDocuments: builder.query({
      query: () => `/accounts/documents/`,
      keepUnusedDataFor: 0
    }),
    getAttendanceEmployees: builder.query({
      query: () => `/attendance/employee_list/`,
      keepUnusedDataFor: 0
    }),
    getAttendanceEmployeesHistory: builder.query({
      query: (id) => `/attendance/employee_attendance_history/${id}/`,
      keepUnusedDataFor: 0
    }),
    getDocumentCategory: builder.query({
      query: () => `/accounts/document_categories/`,
      keepUnusedDataFor: 0
    }),
    // getOrganisationBroadcastHistory: builder.query({
    //   query: () => `/organisation/get_branch_broadcast_history/`,
    //   keepUnusedDataFor: 0
    // }),
    getCalendarEvents: builder.query({
      query: () => `/calendar_manager/calendarevents/`,
      keepUnusedDataFor: 0
    }),
    getPlugins: builder.query({
      query: () => `/plugins/plugins/`,
      keepUnusedDataFor: 0
    }),
    updatePlugin: builder.mutation({
      query: (data) => ({
        url: "/plugins/plugins/", // Replace with your API endpoint for creating users
        method: "PUT",
        body: data, // You should pass your FormData here
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (data) => ({
        url: "/accounts/profile/", // Replace with your API endpoint for creating users
        method: "PUT",
        body: data, // You should pass your FormData here
      }),
    }),
    createCalendarEvent: builder.mutation({
      query: (data) => ({
        url: "/calendar_manager/calendarevents/",
        method: "POST",
        body: data, // You should pass your FormData here
      }),
    }),
    deleteCalendarEvent: builder.mutation({
      query: (event_id) => ({
        url: `/calendar_manager/calendarevents/${event_id}/`, // Replace with your API endpoint for creating users
        method: "DELETE"
      }),
    }),
    updateOrganisations: builder.mutation({
      query: (userFormData) => ({
        url: "/accounts/update_organisation/", // Replace with your API endpoint for creating users
        method: "PUT",
        body: userFormData, // You should pass your FormData here
      }),
    }),
    removeParticipants: builder.mutation({
      query: (data) => ({
        url: "/organisation/participant/", // Replace with your API endpoint for creating users
        method: "DELETE",
        body: data, // You should pass your FormData here
      }),
    }),
    updateParticipantPermission: builder.mutation({
      query: (data) => ({
        url: "/organisation/update_permissions/", // Replace with your API endpoint for creating users
        method: "POST",
        body: data, // You should pass your FormData here
      }),
    }),
    createOrganisationMembers: builder.mutation({
      query: (userFormData) => ({
        url: "/accounts/create_members/", // Replace with your API endpoint for creating users
        method: "POST",
        body: userFormData, // You should pass your FormData here
      }),
    }),
    createUploadDocument: builder.mutation({
      query: (data) => ({
        url: "/accounts/document_upload/", // Replace with your API endpoint for creating users
        method: "POST",
        body: data, // You should pass your FormData here
      }),
    }),
    deleteDocument: builder.mutation({
      query: (document_id) => ({
        url: `/accounts/document_delete/${document_id}/`, // Replace with your API endpoint for creating users
        method: "DELETE"
      }),
    }),
    createLocation: builder.mutation({
      query: (data) => ({
        url: "/organisation/locations/", // Replace with your API endpoint for creating users
        method: "POST",
        body: data, // You should pass your FormData here
      }),
    }),
    sendBroadcast: builder.mutation({
      query: (data) => ({
        url: "/organisation/send_broadcast/", // Replace with your API endpoint for creating users
        method: "POST",
        body: data, // You should pass your FormData here
      }),
    }),
    deleteLocation: builder.mutation({
      query: (data) => ({
        url: "/organisation/locations/", // Replace with your API endpoint for creating users
        method: "DELETE",
        body: data, // You should pass your FormData here
      }),
    }),
    createBranch: builder.mutation({
      query: (data) => ({
        url: "/organisation/branch/", // Replace with your API endpoint for creating users
        method: "POST",
        body: data, // You should pass your FormData here
      }),
    }),
    getOrganisationBranch: builder.query({
      query: () => `/organisation/branch/`,
      keepUnusedDataFor: 0
    }),
    updateChannelMessage: builder.mutation({
      query: (data) => ({
        url: "/organisation/update_group_message/", // Replace with your API endpoint for creating users
        method: "POST",
        body: data,
      }),
    }),
    deleteBranch: builder.mutation({
      query: (data) => ({
        url: "/organisation/branch/", // Replace with your API endpoint for creating users
        method: "DELETE",
        body: data, // You should pass your FormData here
      }),
    }),
    getUsersTemplate: builder.query({
      query: () => ({
        url: "/organisation/get_user_excel/",
        // responseType: 'arraybuffer',
        responseHandler: (response) => {
          return response
        },
      }),
      keepUnusedDataFor: 0,
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: "/accounts/deactivate-user/", // Replace with your API endpoint for creating users
        method: "POST"
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetOrganisationLocationsQuery,
  useGetOrganisationMembersQuery,
  useGetUserProfileQuery,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useDeleteBranchMutation,
  useRemoveParticipantsMutation,
  useDeleteDocumentMutation,
  useUpdateParticipantPermissionMutation,
  useGetChannelDetailsQuery,
  useUpdateOrganisationsMutation,
  useCreateUploadDocumentMutation,
  useGetDocumentCategoryQuery,
  useGetMyDocumentsQuery,
  useCreateOrganisationMembersMutation,
  useCreateBranchMutation,
  useGetOrganisationLocationBranchesQuery,
  useGetUsersTemplateQuery,
  useSendBroadcastMutation,
  useGetCalendarEventsQuery,
  useCreateCalendarEventMutation,
  useDeleteCalendarEventMutation,
  useGetFailedUploadMembersQuery,
  useGetOrganisationBranchQuery,
  useUpdateChannelMessageMutation,
  useGetOrganisationBroadcastHistoryQuery,
  useDeleteAccountMutation,
  useGetUserDetailsQuery,
  useGetDashboardDataQuery,
  useGetPluginsQuery,
  useUpdatePluginMutation,
  useGetAttendanceEmployeesQuery,
  useGetAttendanceEmployeesHistoryQuery,
  useUpdateUserProfileMutation
} = usersApiSlice;
