import { apiSlice } from "../../app/api/apiSlice";

export const channelsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChannels: builder.query({
      query: ({ parent_id }) => {
        if (parent_id) {
          return `/organisation/?parent_id=${parent_id}`;
        } else {
          return `/organisation/`;
        }
      },
      keepUnusedDataFor: 0,
    }),
    getSubscribedChannels: builder.query({
        query: () => `/organisation/my_groups/`,
        keepUnusedDataFor: 0,
      }),
    
    createChannels: builder.mutation({
        query: (data) => ({
          url: '/organisation/', 
          method: 'POST',
          body: data,
        }),
      }),
  }),
});

export const { useGetChannelsQuery, useGetSubscribedChannelsQuery, useCreateChannelsMutation } = channelsApiSlice;
