import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { Table } from "react-bootstrap";
import { useGetPluginsQuery, useUpdatePluginMutation } from "../../features/users/usersApiSlice";
import { formatDate } from "../utils/utils";

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import "./plugins_popup.scss";

export const PluginsPopup = ({ show, onClose }) => {
  const {
    data: pluginData,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetPluginsQuery();

  const [
    updatePlugin,
    { isLoading: updatePluginIsLoading }
   ] = useUpdatePluginMutation();

  const tableStyle = {
    // border: "1px solid #ccc",
    borderRadius: "8px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const updatePluginHandler = async (id) => {
    const data = {
      id
    }
    const response = await updatePlugin(data);
    console.log(response);
    if (response.data && response.errors === undefined) {
      await refetch();
    }
  }

  const tableHeaderStyle = {
    backgroundColor: "#f8fafc",
    color: "white",
  };

  const rowStyle = {
    // backgroundColor: "rgba(87, 166, 250, 0.0705882353)",
    margin: "10px"
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: 'none'
  };

  useEffect(() => {
    if (show) {
      refetch()
    }
  },
    [show])

  return (
    <div>
      <Popup
        modal
        className="plugins-popup-popup"
        open={show}
        onClose={() => {
          onClose();
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
          }}
        >
          x
        </button>
        <div className="plugins-popup-outer">
          <div className="popup-heading">Plugins</div>
          <div className="popup-content-inner">
            {pluginData}
            {pluginData && pluginData.data.map((pluginData) => {
              return <div className="plugin-outer">
                <div
                  className="plugin-inner"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}>
                  <div className="plugin-image-outer"><img src={pluginData.logo} /></div>
                  <div className="plugin-name">{pluginData.name}</div>
                  <div className="plugin-checkbox-outer">
                  <BootstrapSwitchButton
                      checked={pluginData.active}
                      // onlabel='Admin User'
                      // offlabel='Regular User'
                      size="sm"
                      onChange={(checked) => {
                        updatePluginHandler(pluginData.id)
                      }}
                    />
                    
                    </div>
                </div>
              </div>
            })
            }
          </div>
        </div>
      </Popup>
    </div>
  );
};
