import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import LogoBlue from '../../../assets/img/logo-blue.png'
import { useSelector } from "react-redux";

import { Container, Navbar, NavDropdown } from "react-bootstrap";
import { FaHome, FaMapMarkedAlt } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { GrChannel } from "react-icons/gr";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../../features/auth/authSlice";
import { selectChannels } from "../../../features/channels/channelSlice";
import { useGetSubscribedChannelsQuery } from "../../../features/channels/channelsApiSlice";
import Nav from "react-bootstrap/Nav";
import { logOut } from "../../../features/auth/authSlice";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import { setChannels } from "../../../features/channels/channelSlice";
import { store } from "../../../app/store";
// import logo from '../assets/images/logo.png'
import { SocialIcon } from "react-social-icons";
import avatar3 from '../../../assets/img/avatar3.png'
import "./styles.scss";
import { setGroups } from "../../../features/channels/channelSlice";
import { RxHamburgerMenu } from "react-icons/rx";
import { PiCaretLeftBold } from "react-icons/pi";


const Layout2 = () => {
  const user = useSelector(selectCurrentUser);
  const channels = useSelector(selectChannels);
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const {
    data: user_data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery();

  console.log("sdgdsgg", user_data);
  
  if (user_data?.data.is_staff === false) {
    store.dispatch(logOut());
  }

  const LogoutHandle = () => {
    store.dispatch(logOut());
  };

  // const {
  //   data: channels_data,
  //   isLoading: channelIsLoading,
  //   isSuccess: channelIsSuccess,
  //   isError: channelIsError,
  //   error: channelError,
  //   refetch,
  // } = useGetSubscribedChannelsQuery();
  // if (channels_data && channels_data.data) {
  //   store.dispatch(setChannels(channels_data.data));
  // }
  // useEffect(() => {
  //   refetch();
  // }, []);

  return (
    <main className="App">
      {/* <Navbar className="outer-landing-navbar">
        <Container className="landing-navbar">
          <Navbar.Brand href="#home">
            <img className="logo-image" />
          </Navbar.Brand>
          <Nav className="me-auto">
            {user && (user.organisation.name !== "" || user.organisation.name === null) ?
              <Nav.Link href="/home">
                <img src={user.organisation.logo} style={{ height: '26px' }} /> <span style={{
                  marginLeft: '10px',
                  fontWeight: "bold"
                }}>{user.organisation.name}</span>
              </Nav.Link>
              :
              <Nav.Link href="/home">
                <img src={LogoBlue} style={{ height: '27px' }} />
              </Nav.Link>
            }
          </Nav>
          <Navbar.Toggle />
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">

            <NavDropdown
              title={<img className="user-dropdown-image" src={avatar3} />}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/account">Accounts</NavDropdown.Item>
              <NavDropdown.Item onClick={LogoutHandle}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
          <div></div>
        </Container>
      </Navbar> */}
      <div className="layout2-inner">
        {user && (user.organisation.name_nn !== "" || user?.organisation.name_nn === null) && (user?.organisation.is_initial_members_added_ind) &&
          <div className="layout2-inner-sidebar" style={toggleSidebar ? { width: "250px" } : {}}>
            <Sidebar
              className="lala"
              rootStyles={{
                ["lala"]: { backgroundColor: "#1d1d1f" },
                [`.abc`]: {
                  backgroundColor: "red",
                },
              }}
            >
              {/* <div className="greetings">
                Hi{" "}
                <span style={{ color: "#0098e5", fontWeight: "bold" }}>
                  {user_data && user_data.response_data.first_name}
                </span>
              </div> */}
              <Menu>
                {user && user?.organisation && user?.organisation.name !== "" ? (
                  <>
                    {/* <div className="organisation-outer">
                      <div className="organisation-name">
                        {user.organisation.name}
                      </div>
                      <div className="organisation-logo-outer">
                        <img
                          className="organisation-logo"
                          src={user.organisation.logo}
                        />
                      </div>
                    </div> */}
                    <div style={{
                      textAlign: "center",
                      width: "100%",
                      padding: "49px 10px 10px 10px",
                      borderRadius: "6px",
                      marginBottom: "10px"
                    }}>
                      {user && (user?.organisation.name !== "" || user?.organisation.name === null) ?
                        <Link to="/home">
                          <img src={user?.organisation.logo} style={{ height: '26px', background: "white", borderRadius: "5px" }} /> <span style={{
                            marginLeft: '10px',
                            fontWeight: "bold"
                          }}>{user?.organisation.name}</span>
                        </Link>
                        :
                        <Nav.Link href="/home">
                          <img src={LogoBlue} style={{ height: '27px', background: "white", borderRadius: "5px" }} />
                        </Nav.Link>
                      }
                      <button className="sidebar-collapse-button" onClick={() => { setToggleSidebar(!toggleSidebar) }}><PiCaretLeftBold /></button>
                    </div>
                    <hr style={{ color: "white", opacity: "0.7" }} />
                    <div className="sidebar-heading">Main Menu</div>
                    <div>
                      {/* <hr /> */}
                      <Link to="/home">
                        <MenuItem
                          className={
                            location.pathname === "/home" ? "link-active" : ""
                          }
                        >
                          <FaHome style={{ marginRight: '10px' }} /> Home
                        </MenuItem>
                      </Link>
                      <Link to="/members">
                        <MenuItem
                          className={
                            location.pathname === "/members" ? "link-active" : ""
                          }
                        >
                          <BsPeopleFill style={{ marginRight: '10px' }} /> Members
                        </MenuItem>
                      </Link>
                      <Link to="/map">
                        <MenuItem
                          className={
                            location.pathname === "/map"
                              ? "link-active"
                              : ""
                          }
                        >
                          <FaMapMarkedAlt style={{ marginRight: '10px' }} /> Map
                        </MenuItem>
                      </Link>
                      <Link to="/attendance">
                        <MenuItem
                          className={
                            location.pathname === "/attendance"
                              ? "link-active"
                              : ""
                          }
                        >
                          <FaMapMarkedAlt style={{ marginRight: '10px' }} /> Attendance
                        </MenuItem>
                      </Link>
                      {/* <Link to="/calender">
                        <MenuItem
                          className={
                            location.pathname === "/calender"
                              ? "link-active"
                              : ""
                          }
                        >
                          <SlCalender style={{ marginRight: '10px' }} />Calender
                        </MenuItem>
                      </Link>
                      <Link to="/manage_channels">
                        <MenuItem
                          className={
                            location.pathname === "/manage_channels"
                              ? "link-active"
                              : "" 
                          }
                        >
                          <MdManageAccounts style={{ marginRight: '10px' }} />Manage Channels
                        </MenuItem>
                      </Link> */}
                    </div>
                  </>
                ) : (
                  <div className="no-organisation">No Organisation</div>
                )}
                {/* <div className="sidebar-heading">Channels</div>
                <div style={{ overflow: "scroll", flexGrow: 1 }}>
                  {channels &&
                    channels.map((channel) => {
                      return (
                        <Link
                          className="channel-link"
                          to={`/channel/${channel.id}/`}
                        >
                          <MenuItem
                            className={
                              location.pathname === `/channel/${channel.id}/`
                                ? "channel-outer link-active"
                                : "channel-outer"
                            }
                          >
                            # {channel.name}
                          </MenuItem>
                        </Link>
                      );
                    })}
                </div> */}
              </Menu>
            </Sidebar>
          </div>
        }
        <div className="layout2-inner-main">

          <Navbar className="outer-landing-navbar">
            <Container className="landing-navbar">
              <button className="left-hamburger-icon">
                <RxHamburgerMenu onClick={() => {
                  setToggleSidebar(!toggleSidebar)
                }} /></button>

              <Navbar.Toggle />
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">

                <NavDropdown
                  title={
                    <div>
                      <img className="user-dropdown-image" src={avatar3} /> <span style={{ fontWeight: "bold" }}>Admin</span>
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item href="/account">Accounts</NavDropdown.Item>
                  <NavDropdown.Item onClick={LogoutHandle}>Logout</NavDropdown.Item>
                  {/* <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item> */}
                </NavDropdown>
                {/* <Dropdown drop='start down'>
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                <img className="user-dropdown-image" src={PersonImage3} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown> */}
              </Navbar.Collapse>
              <div></div>
              {/* <Navbar.Collapse className="justify-content-end">
                        <Link to={'login'} >
                        <Button variant="link" className='login-btn'>Sign In</Button>
                        </Link>
                    </Navbar.Collapse> */}
            </Container>
          </Navbar>
          <div style={{ marginTop: '60px', height: 'calc(100% - 60px)', width: '100%', flexGrow: "1", overflow: "scroll" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout2;
