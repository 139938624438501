import { React, useRef, useState, useEffect } from 'react';
// import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import '../assets/css/pages/login.scss'
import LoginLeftBg from '../../assets/img/login-left-bg.png'
import Logo from '../../assets/img/logo.png'
import LogoBlue from '../../assets/img/logo-blue.png'
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { useDispatch } from 'react-redux';
import { MdOutlineEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { store } from '../../app/store';
import { logOut } from '../../features/auth/authSlice';
import { TailSpin } from 'react-loader-spinner'
import { setCredentials } from '../../features/auth/authSlice';
// import useToggle from '../hooks/useToggle';
// import useInput from '../hooks/useInput';
// import axios from '../api/axios';
import './styles.scss'

const LOGIN_URL = '/auth/login/';

const Login = () => {
    const userRef = useRef()
    const errRef = useRef()
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [check, setCheck] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [login, { isLoading }] = useLoginMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        store.dispatch(logOut())
        userRef.current.focus()
    }, [])

    const toggleCheck = () => {
        setCheck(!check)
    }

    useEffect(() => {
        setErrMsg('')
    }, [user, pwd])

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    // const validatePassword = (password) => {
    //     const minLength = 8;
    //     const hasUppercase = /[A-Z]/.test(password);
    //     const hasLowercase = /[a-z]/.test(password);
    //     const hasDigit = /\d/.test(password);
    //     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    //     return password.length >= minLength && hasUppercase && hasLowercase && hasDigit && hasSpecialChar;
    // };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!validateEmail(user)) {
            setErrMsg('Invalid email format');
            return;
        }

        // if (!validatePassword(pwd)) {
        //     setErrMsg('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character');
        //     return;
        // }


        try {
            setLoading(true)
            store.dispatch(logOut());
            const userData = await login({ email: user, password: pwd }).unwrap();
            setLoading(false)
            console.log("userData: ", userData.data);
            
            dispatch(setCredentials({ ...userData.data, user }))
            setUser('')
            setPwd('')
            navigate('/home')
        } catch (err) {
            setLoading(false)
            if (!err?.originalStatus) {
                // console.log("ERROR IS: ", err.data.data);
                // isLoading: true until timeout occurs
                setErrMsg(err.data.data);
            } else if (err.originalStatus === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.originalStatus === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    };

    // useEffect(() => {
    //     const isFormValid = validateEmail(user) && check;
    //     document.getElementById('submitButton').disabled = !isFormValid;
    // }, [user, pwd, check]);


    const handleUserInput = (e) => setUser(e.target.value)

    const handlePwdInput = (e) => setPwd(e.target.value)

    return (

        <section>
            <div className='login-outer'>
                <div className='login-inner'>
                    <div className='login-inner-left' >
                        <img src={LoginLeftBg} style={{ width: '80%', overflow: 'hidden', margin: "auto" }} />
                        <img className="left-logo" src={Logo} />
                    </div>
                    <div className='login-inner-right'>
                        <div>
                            <div className='form-logo-outer'>
                                <img className="form-logo" src={LogoBlue} />
                            </div>
                            <p style={{ color: 'red' }} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                            <div className='login-heading'>Login</div>
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <label htmlFor="email">Email</label>
                                <div className="wrapper">
                                    <div className="icon">
                                        <MdOutlineEmail />
                                    </div>
                                    <input
                                        type="text"
                                        id="username"
                                        className="input"
                                        ref={userRef}
                                        value={user}
                                        placeholder='Email'
                                        onChange={handleUserInput}
                                        autoComplete="new-password"
                                        required
                                    />
                                </div>

                                <label htmlFor="password">Password</label>
                                <div className="wrapper">
                                    <div className="icon">
                                        <CiLock />
                                    </div>
                                    <input
                                        className="input"
                                        type="password"
                                        id="password"
                                        placeholder='Password'
                                        autoComplete="new-password"
                                        onChange={handlePwdInput}
                                        value={pwd}
                                        required
                                    />
                                </div>
                                <div className="terms">
                                    <input
                                        type="checkbox"
                                        onChange={toggleCheck}
                                        checked={check}
                                    />
                                    <label htmlFor="persist">By logging with an account means you agree to the <span style={{ color: 'black' }}>Terms
                                        & Conditions</span> and our <span style={{ color: 'black' }}>Privacy Policy</span></label>
                                </div>
                                <button style={check && !loading ? {} : { background: 'grey' }} className='signin-btn'>
                                    {loading && <span
                                    style={{marginRight: "10px"}}
                                    ><TailSpin
                                        visible={true}
                                        height="30"
                                        width="30"
                                        color="#03a9f4"
                                        ariaLabel="tail-spin-loading"
                                        radius="2"
                                    />
                                    </span>
                                    }
                                    <span>
                                        Login
                                    </span>
                                </button>
                            </form>

                            {/* <p className='signup-outer'>
                                Don't have an account?
                                <span className="line">
                                    <Link className="signup" to="/register">&nbsp;Sign Up</Link>
                                </span>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Login
