import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../features/auth/authSlice";
import { useGetDashboardDataQuery } from "../../features/users/usersApiSlice";
import { React, useState, useContext, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { NotificationManager } from 'react-notifications';
import NewOrgansation from "../../components/NewOrgansation";
import BannerBg from "../../../src/assets/img/home-banner-img.png";

import "./styles.scss";

// import './styles.scss'

const Home = () => {
  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);

  const [activeKey, setActiveKey] = useState("tab1");
  const tokenAbbr = `${token.slice(0, 9)}...`;

  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  const {
    data: dashboardData,
    isLoading: isLoadingDashboardData,
    isSuccess: isSuccessDashboardData,
    isError: isErrorDashboardData,
    error: errorDashboardData,
    refetch,
  } = useGetDashboardDataQuery();

  const navigate = useNavigate();
  
  const edit_oraganisation = () => {
    NotificationManager.success('Success message', 'Title here');
  }

  useEffect(()=> {
    refetch()
  },
  []);

  console.log(" dfjsklfjhsdjhfs   " + user, user.organisation.name_nn)


  return (
    <section className="layout-inner">
      {((user && (user.organisation.name !== "" || user.organisation.name_nn === null) && (user.organisation.is_initial_members_added_ind))) ? 
        (<>
          
        <div className="show-organisation-outer">
        
          <div className="row" style={{height:" 100%"}}>
            <div className="col-8 left-data">
            <div className="layout-heading">
            <h2>Welcome Back, Admin</h2>
            <div>Let's finish your task today</div>
            <div className="banner-bg"><img src={BannerBg}/></div>
          </div>
              <div className="counter-outer">

                <div className="counter-heading">Overview</div>
                <div className="row" style={{margin: '0px'}}>
                <div className="col-4 employee-counter-outer">
                  <div className="heading">
                    Total Employees
                  </div>
                  <div className="count">
                    {dashboardData && dashboardData.members_count}7
                  </div>
                  <div className="sub-heading">
                    Employees on Board
                  </div>
                </div>
                <div className="col-4 channel-counter-outer">
                <div className="heading">
                    Channels
                  </div>
                  <div className="count">
                  {dashboardData && dashboardData.channel_count}8
                  </div>
                  <div className="sub-heading">
                    Channels Used
                  </div>
                </div>
              </div>
              </div>
              <div className="activities-outer">
                <div className="title">Activities</div>
                <div className="no-activities">No Activies</div>
              </div>
            </div>
            <div className="col-4 organisation-info-outer">
              <div className="page-heading-outer">
              <div className="page-heading">Organisation</div>
              {/* <button
                className="edit-btn"
                onClick={()=> {edit_oraganisation()}}
              >
              Edit
              </button> */}
              </div>
            <div className="company-logo-outer">
              <img src={user.organisation.logo} className="company-logo" />
              <div className="organisation-name">{user.organisation.name}</div>
            </div>
            <div className="company-details">
              <div className="name">
                <span className="label"><span>Name</span><span>:</span></span> {user.organisation.name}
              </div>
              <div className="description">
                <span className="label"><span>Description</span><span>:</span></span>{" "}
                {user.organisation.description}
              </div>
              <div className="founded-in">
                <span className="label"><span>Established In</span><span>:</span></span>{" "}
                {user.organisation.founded_year}
              </div>
              <div className="headquater">
                <span className="label"><span>Headquarter</span><span>:</span></span>{" "}
                {user.organisation.headquarters}
              </div>
              <div className="email">
                <span className="label"><span>Email</span><span>:</span></span>{" "}
                {user.organisation.contact_email}
              </div>
              <div className="phone">
                <span className="label"><span>Phone</span><span>:</span></span>{" "}
                {user.organisation.phone_number}
              </div>
              <div className="website">
                <span className="label"><span>Website</span><span>:</span></span>{" "}
                {user.organisation.website}
              </div>
            </div>
            </div>

          </div>
         
        </div>
        </>
      ) : (
        <NewOrgansation />
      )}
    </section>
  );
};

export default Home;
