import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { Table } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import Select, { components, MenuListProps } from "react-select";
import axios from 'axios';
import * as XLSX from "xlsx";
import { NotificationManager } from 'react-notifications';
import {
  AiOutlineInfoCircle,
  AiFillPlusCircle,
  AiOutlineMinusCircle,
  AiOutlineDownload,
} from "react-icons/ai";
import { BiShowAlt } from "react-icons/bi";
import { useGetOrganisationBranchQuery, useCreateOrganisationMembersMutation } from "../../features/users/usersApiSlice";
// import { formatDate } from "../utils/utils";
import { excelDateToJSDate, formatDateToYYYYMMDD } from "../utils/utils";
import SampleUploadFile from '../../assets/img/sample_upload_file.png'
import "./upload_members.scss";

export const UploadMemberPopup = ({ show, onClose }) => {
  const user = useSelector(selectCurrentUser);
  const {
    data: branchData,
    isLoading: isLoadingBranchData,
    isSuccess: isSuccessBranchData,
    isError: isErrorBranchData,
    error: errorBranchData,
    refetch,
  } = useGetOrganisationBranchQuery();
  const [createOrganisationMembers, { isLoading: createOrganisationMembersIsLoading }] =
    useCreateOrganisationMembersMutation();
  const [selectedExcelFile, setSelectedExcelFile] = useState(null);
  const [key, setKey] = useState("excel");
  const [branch, setBranch] = useState(null);
  const [membersList, setMembersList] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    phoneNumber: '',
    education: '',
    experience: '',
    gender: ''
  });
  const [branchOptions, setBranchOptions] = useState([
  ]);

  useEffect(()=> {
    setMembersList([])
  },[
    key
  ])


  const excelDateToDateString = (excelDate) => {
    if (!excelDate) {
      return ""; // Handle null or undefined values
    }

    // Excel uses January 1, 1900, as day 1
    const excelDateOffset = 25569; // Number of days between January 1, 1900, and January 1, 1970
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const dateMilliseconds = (excelDate - excelDateOffset) * millisecondsPerDay;

    // Create a Date object and format it as 'yyyy-MM-dd'
    const formattedDate = new Date(dateMilliseconds).toISOString().slice(0, 10);
    return formattedDate;
  };

  const tableStyle = {
    // border: "1px solid #ccc",
    borderRadius: "8px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddMember = (e) => {
    e.preventDefault();
    const data = {
      "Branch": "0d79bfa5-801a-42e6-8dcb-6514ab54f14f",
      "First Name": formData.firstName,
      "Last Name": formData.lastName,
      "Email": formData.email,
      "Date Of Birth": formData.dob,
      "Phone": formData.phoneNumber,
      "Gender": formData.gender,
      "Education": formData.education,
      "Experience": formData.experience
    }
    setMembersList([...membersList, data])
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      phoneNumber: '',
      education: '',
      experience: '',
      gender: ''
      // Reset other fields as needed
    });
    setShowForm(false)
  };
  const downloadTemplate = async () => {
    try {
      axios({
        url: `http://localhost:8000/organisation/get_user_excel/${user.organisation.id}/`, //your url
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'UploadTemplate.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    } catch (error) {
      console.error('Error downloading template:', error);
    }
  };
  const handleReadExcel = (file) => {
    console.log(file, "asgags");
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        processExcelData(data);
      };
      reader.readAsBinaryString(file);
    } else {
      alert("Please select an Excel file.");
    }
  };
  const processExcelData = (data) => {
    const workbook = XLSX.read(data, { type: 'binary', encoding: 'UTF-8' });
    console.log(workbook);
    const sheetNames = workbook.SheetNames;
    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]], {
      defval: null,
    });
    console.log(sheetData);
    const formattedData = sheetData.map((item) => {
      return {
        "First Name": item["First Name"],
        "Last Name": item["Last Name"],
        "Email": item["Email"],
        "Date Of Birth": formatDateToYYYYMMDD(excelDateToJSDate(item["Date Of Birth"])),
        "Branch": item["Branch"],
        "Phone": item["Phone"].toString(),
        "Gender": item["Gender"],
        "Education": item["Education"],
        "Role": item["Role"],
        "Designation": item['Designation'],
        "Experience": item["Experience"],
        "Department": item['Department'],
        "Sub Department": item['Sub Department'],
        "Address": item["Address"]
    }
    })
    setMembersList(formattedData);
  };
  const handleExcelFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedExcelFile(file);
    handleReadExcel(file)
  };

  const SubmitOrganisationMembersHandler = async () => {
    const data = {
      "members": membersList
    }
    const response = await createOrganisationMembers(data);
    console.log(response);
    if (response.data && response.errors === undefined) {
      NotificationManager.success('Members added', 'Members added successfully');
      onClose();
    }
    else {
      NotificationManager.error('Members not added', 'Members adding failed');
    }

    // const result = await refetch();
    // dispatch(setCredentials({user: null, user_data: result.data.response_data.user_data, access: token }))
  };
  

  const removeFromMemberList = (indexToRemove) => {
    console.log(membersList, indexToRemove);
    setMembersList(membersList.filter((_i, index) => index !== indexToRemove));
  };

  const tableHeaderStyle = {
    backgroundColor: "#f8fafc",
    color: "white",
  };

  const rowStyle = {
    // backgroundColor: "rgba(87, 166, 250, 0.0705882353)",
    margin: "10px"
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: 'none'
  };

  const setBranchHandler = (event) => {
    setBranch(event);
    // refetch()
  };
  const reloadBranches = async () => {
    const branches = await refetch()
      console.log("ABC: ", branches);
      const formatted_branch_data = branches.data.data.map((item) => {
        return {
          label: item.branch_name,
          value: item.id
        }
      })
      setBranchOptions(formatted_branch_data)
  }

  useEffect(() => {
    if (show) {
      reloadBranches()
    }
  },
    [show])

  return (
    <div>
      <Popup
        modal
        className="upload-members-popup"
        open={show}
        onClose={() => {
          onClose();
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
          }}
        >
          x
        </button>
        <div className="upload-members-outer">
          <div className="popup-heading">Add Members</div>
          {!showForm ? <div className="popup-content-inner-tabs">
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="accounts-tabs"
              fill
            >
              <Tab eventKey="excel" title="Sheet">
                <div className="template-upload-outer">
                  <div style={{ flexGrow: 1 }}>
                    <div className="organisation-name-label-sub">
                      Please upload a list users by filling this template
                      accordingly and reuploading.
                    </div>
                    <div className="download-template-outer">
                      <div className="download-template-btn-outer">
                        <button
                          className="download-template-btn"
                          onClick={() => {
                            downloadTemplate();
                          }}
                        >
                          <AiOutlineDownload /> Download Template
                        </button>
                        <span className="show-template-icon"><BiShowAlt /></span>
                        <div className="sample-upload-template-container">
                            <div>Please fill each row with valid data as shown below</div>
                            <img className="sample-upload-template" src={SampleUploadFile}/>
                        </div>
                      </div>
                    </div>

                    <div className="update-sheet-label">
                      Please reupload the update excel sheet here
                    </div>
                    <div className="update-sheet-input" style={{ textAlign: "center" }}>
                      <input
                        type="file"
                        id="organisation-name"
                        accept=".xlsx,.xlx"
                        onChange={handleExcelFileChange}
                      />
                    </div>
                  </div>
                  <div className="functional-btns">
                    <button className="upload-btn" onClick={()=> {SubmitOrganisationMembersHandler()}}>Update</button>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="form" title="Form">
                {/* <div className="tab-heading">Organisation Members</div> */}
                <div className="manual-add-member-outer">
                  <div style={{ flexGrow: 1 }}>
                    <div className="add-member-btn-outer">
                      <button className="add-member-btn" onClick={() => { setShowForm(true) }}>Add Member</button>
                    </div>
                    <div className="organisation-name-label-sub">
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Date Of Birth</th>
                          <th>Branch</th>
                          <th>Phone</th>
                          <th>Gender</th>
                          <th>Education</th>
                          <th>Experience</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {membersList.map((candidate, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{candidate["First Name"]}</td>
                            <td>{candidate["Last Name"]}</td>
                            <td>{candidate["Email"]}</td>
                            <td>
                              {candidate["Date Of Birth"]}
                            </td>
                            <td>{candidate["Branch"]}</td>
                            <td>{candidate["Phone"]}</td>
                            <td>{candidate["Gender"]}</td>
                            <td>{candidate["Education"]}</td>
                            <td>{candidate["Experience"]}</td>
                            <td><button className="remove-btn" onClick={() => { removeFromMemberList(index) }}>Remove</button></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                  </div>
                  <div className="functional-btns">
                    <button className="upload-btn" onClick={()=> {SubmitOrganisationMembersHandler()}}>Update</button>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div> :
            <div className="popup-content-inner-form">
              {/* <div className="tab-heading">Add Member</div> */}
              <form onSubmit={handleAddMember}>
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="dob">Date Of Birth</label>
                  <input
                    type="text"
                    id="dob"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="education">Branch</label>
                  <Select
                    isSearchable={false}
                    className="dropdown-item"
                    value={branch}
                    onChange={setBranchHandler}
                    options={branchOptions}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="education">Education</label>
                  <input
                    type="text"
                    id="education"
                    name="education"
                    value={formData.education}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="experience">Experience</label>
                  <input
                    type="number"
                    id="experience"
                    name="experience"
                    value={formData.experience}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="gender">Gender</label>
                  <input
                    type="text"
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                  />
                </div>
                {/* Add more input fields as needed */}
                <div className="functional-btns">
                  <button  className="submit-btn" type="submit">Add Member</button>
                  <button className="cancel-btn" onClick={()=> {setShowForm(false)}}>Cancel</button>
                </div>
              </form>
            </div>
          }
        </div>
      </Popup>
    </div>
  );
};
