import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../features/auth/authSlice";
import { Table } from "react-bootstrap";
import { AddDocumentPopup } from "../../components/popups/AddDocumentPopup";
import {
  useGetUserProfileQuery,
  useGetMyDocumentsQuery,
  useDeleteAccountMutation,
  useDeleteDocumentMutation,
  useUpdateUserProfileMutation,
} from "../../features/users/usersApiSlice";
import { React, useState, useContext, useRef, useEffect } from "react";
import { formatDate, convertBytesToAuto } from "../../components/utils/utils";
import JpgFileIcon from "../../assets/img/jpg-file.png";
import JpegFileIcon from "../../assets/img/jpeg-file.png";
import PngFileIcon from "../../assets/img/png-file.png";
import PdfFileIcon from "../../assets/img/pdf-file.png";
import DocFileIcon from "../../assets/img/doc-file.png";
import { MdVerified } from "react-icons/md";
import { AiOutlineCloseCircle, AiOutlineDelete } from "react-icons/ai"
import { Tooltip } from 'react-tooltip'
import { logOut } from "../../features/auth/authSlice";
import Button from "react-bootstrap/Button";
import { store } from "../../app/store";

import NewOrgansation from "../../components/NewOrgansation";
import Avatar3 from "../../assets/img/avatar.jpg";

import "./styles.scss";

// import './styles.scss'

const Account = () => {
  const [profileData, setProfileData] = useState({})
  const {
    data: profile_data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetUserProfileQuery();

  const {
    data: documentsRefetchData,
    isLoading: documentsRefetchIsLoading,
    isSuccess: documentsRefetchIsSuccess,
    isError: documentsRefetchIsError,
    error: documentsRefetchError,
    refetch: documents_refetch,
  } = useGetMyDocumentsQuery();

  // const [profileData, setProfileData] = useState({});
  const [editProfileData, setEditProfileData] = useState({});

  const [key, setKey] = useState("profile");
  const [showAddDocumentPopup, setShowAddDocumentPopup] = useState(false);

  const getFileTypeImage = (type) => {
    switch (type) {
      case "png":
        return PngFileIcon;
        break;
      case "jpg":
        return JpgFileIcon;
        break;
      case "jpeg":
        return JpegFileIcon;
        break;
      case "doc":
        return DocFileIcon;
        break;
      case "pdf":
        return PdfFileIcon;
        break;
    }
  };

  const tableStyle = {
    // border: "1px solid #ccc",
    borderRadius: "8px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const tableHeaderStyle = {
    backgroundColor: "#0076f4",
    color: "white",
  };

  const rowStyle = {
    backgroundColor: "rgba(87, 166, 250, 0.0705882353)",
    margin: "10px",
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: "none",
  };

  const onAddDocumentPopupClose = () => {
    setShowAddDocumentPopup(false);
  };

  const submitAddNewDocumentHandler = (data) => {
    documents_refetch();
  };

  const [deleteDocument, { isLoading: deleteDocumentIsLoading }] = useDeleteDocumentMutation();

    const [deleteAccount, { isLoading: deleteAccountIsLoading }] = useDeleteAccountMutation();
    const [updateUserProfile, { isLoading: updateUserProfileIsLoading }] = useUpdateUserProfileMutation();

  const DeleteAccountHandler = async () => {
    // formData.append("members", JSON.stringify(membersList))

    const response = await deleteAccount();
    if (response.data && response.errors === undefined) {
      store.dispatch(logOut());
    }
    // dispatch(setCredentials({user: null, user_data: result.data.response_data.user_data, access: token }))
  };

  const DeleteDocumentHandler = async (id) => {
    // formData.append("members", JSON.stringify(membersList))

    const response = await deleteDocument(id);
    if (response.data && response.errors === undefined) {
      documents_refetch()
    }
  };

  useEffect(() => {
    if (profileData) {
      setEditProfileData(profile_data);
    }
  }, [profileData]);

  const onLoadTrigger = async () => {
    const abc = await refetch();
    setProfileData(abc.data);
    await documents_refetch();
  }

  useEffect(() => {
    onLoadTrigger()
  }, []);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditProfileData({ ...editProfileData, [name]: value });
  };

  const updateProfileHandler = async () => {
    try {
      const response = await updateUserProfile(editProfileData);
      if (response.data && response.errors === undefined) {
        refetch();
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  const cancelUpdateHandler = () => {
    setEditProfileData(profileData);
  };

  return (
    <section className="layout-inner">
      {profileData && profileData.user && (
        <div className="accounts-container-outer">
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="accounts-tabs"
            fill
          >
            <Tab eventKey="profile" title="Profile">
              <div className="row">
                <div className="user-image-outer">
                  <img className="user-image" src={Avatar3} />
                </div>
                <div className="details-outer">
                  <div className="row label-input-group">
                    <div className="col-4 label">First Name</div>
                    <div className="col-8 input">
                      <input value={profileData.user.first_name} disabled />
                    </div>
                  </div>
        
                  <div className="row label-input-group">
                    <div className="col-4 label">Last Name</div>
                    <div className="col-8 input">
                      <input value={profileData.user.last_name} disabled />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Date Of Birth
                    </div>
                    <div className="col-8 input">
                      <input value={profileData.user.date_of_birth} disabled />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Gender</div>
                    <div className="col-8 input">
                      <input value={profileData.gender} disabled />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Languages</div>
                    <div className="col-8 input">
                      <input value={profileData.languages} disabled />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Bio</div>
                    <div className="col-8 input">
                      <input value={profileData.bio} disabled />
                    </div>
                  </div>
                  <div className="heading">Contact Information</div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Email</div>
                    <div className="col-8 input">
                      <input value={profileData.user.email} disabled />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Phone Number</div>
                    <div className="col-8 input">
                      <input disabled value={profileData.user.phone_number} />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Personal Website</div>
                    <div className="col-8 input">
                      <input value={profileData.personal_website} />
                    </div>
                  </div>
                  {/* <div className="row label-input-group">
                    <div className="col-4 label">Others</div>
                    <div className="col-8 input">
                      <input value={profileData.contact_information} />
                    </div>
                  </div> */}
                  <div className="heading">Organisation</div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Designation</div>
                    <div className="col-8 input">
                      <input value={profileData.designation} disabled />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Experience</div>
                    <div className="col-8 input">
                      <input value={profileData.experience} disabled />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Awards</div>
                    <div className="col-8 input">
                      <input
                        value={profileData.awards_recognitions}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row label-input-group">
                    <div className="col-4 label">Conference & Events</div>
                    <div className="col-8 input">
                      <input value={profileData.conference_event} disabled />
                    </div>
                  </div>
                  <div className="action-btns-outer">
                    <button className="action-btn cancel-btn" onClick={cancelUpdateHandler}>Cancel</button>
                    <button className="action-btn accept-btn" onClick={updateProfileHandler}>Update</button>
                  </div>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="documents" title="Documents">
              <div className="documents-outer">
                <div className="add-new-document-outer">
                  <button
                    className="add-new-document"
                    onClick={() => {
                      setShowAddDocumentPopup(true);
                    }}
                  >
                    Add New
                  </button>
                </div>
                {documentsRefetchData && (
                  <div className="category-list-outer">
                    {Object.entries(documentsRefetchData).map(
                      ([key, value]) => {
                        return (
                          <div>
                            <div className="category-heading">{key}</div>
                            {value.map((item, index) => {
                              return (
                                <div className="row category-item">
                                  <div className="col-3">
                                    <img
                                      style={{ height: "100%" }}
                                      src={getFileTypeImage(
                                        item.file_extension
                                      )}
                                    />
                                  </div>
                                  <div className="col-7">
                                    <div style={{ fontWeight: "600" }}>
                                      {item.file_name}
                                    </div>
                                    <div style={{ color: "grey" }}>
                                      {formatDate(item.created_at)} &#x2022;{" "}
                                      {convertBytesToAuto(item.file_size)}
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <div className="verified-outer">
                                      <span
                                        data-tooltip-id="user-verified"
                                        data-tooltip-content="User Verified"
                                        className="verified-badge"
                                        style={item.verified_by_user? {color: "#9cff93"}: {}}
                                        >
                                        <MdVerified />
                                      </span>
                                      <span
                                        data-tooltip-id="organisation-verified"
                                        data-tooltip-content="Organisation Verified"
                                        className="verified-badge"
                                        style={item.verified_by_organisation? {color: "#78e46e"}: {}}
                                      >
                                        <MdVerified />
                                      </span>
                                      <span
                                        data-tooltip-id="antro-verified"
                                        data-tooltip-content="Antro Verified"
                                        className="verified-badge"
                                        style={item.verified_by_antro? {color: "#54a84c"}: {}}
                                      >
                                        <MdVerified />
                                      </span>
                                    </div>
                                    <div className="delete-document-outer">
                                      <button onClick={()=> {DeleteDocumentHandler(item.id)}} className="delete-document-btn"><AiOutlineDelete /></button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            </Tab> */}
            <Tab eventKey="settings" title="Settings">
              <div className="settings-outer">
                    <div className="settings-heading">Delete Account</div>
                    <div className="delete-account-headline">Delete account will do following:</div>
                    <div className="delete-account-line"><span className="delete-info-line-icon"><AiOutlineCloseCircle /></span>Log out on all deviced.</div>
                    <div className="delete-account-line"><span className="delete-info-line-icon"><AiOutlineCloseCircle /></span>Delete your account information.</div>
                    <div className="delete-account-btn-outer">
                      <button className="delete-account-btn" onClick={DeleteAccountHandler}>Delete Account</button>
                    </div>
              </div>
            </Tab>
          </Tabs>
          <AddDocumentPopup
            show={showAddDocumentPopup}
            onSubmit={submitAddNewDocumentHandler}
            onClose={onAddDocumentPopupClose}
          />
          <Tooltip id="user-verified" />
          <Tooltip id="organisation-verified" />
          <Tooltip id="antro-verified" />
        </div>
      )}
    </section>
  );
};

export default Account;
