import React, { Component }  from 'react';
import Layout1 from './components/layouts/layout1/layout1';
import Layout2 from './components/layouts/layout2/layout2';
import Landing from './pages/landing';
import Home from './pages/home';
import Members from './pages/members';
import Account from './pages/account';
import ManageChannel from './pages/manage_channels';
import Map from './pages/map'
import { Routes, Route } from 'react-router-dom';
// import PersistLogin from './components/PersistLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import RequireAuth from './features/auth/RequireAuth';
import Login from './pages/login';
// import Channel from './pages/channel/channel';
import Channel from './pages/channel/channel';
import 'reactjs-popup/dist/index.css';
import 'react-tooltip/dist/react-tooltip.css'
// import Calender from './pages/calender';
import Attendance from './pages/attendance';
import AttendanceHistory from './pages/attendance_history';


import MemberDetails from './pages/member_details';

function App() {
  return (
    <Routes>
      <Route path="/" >
        <Route path="/" element={<Layout1 />}>
          <Route path="/" element={<Landing />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout2 />}>
          <Route element={<RequireAuth />}>
            <Route path="home" element={<Home />} />
            <Route path="members" element={<Members />} />
            <Route path="member/:userId/" element={<MemberDetails />} />
            <Route path="/account" element={<Account />} />
            <Route path="/map" element={<Map />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/attendance-history/:id/" element={<AttendanceHistory />} />
            {/* <Route path="/calender" element={<Calender />} /> */}
            <Route path="/manage_channels" element={<ManageChannel />} />
            <Route path="/channel/:channelId/" element={<Channel />} />
          </Route>
        </Route>
        
      </Route>
    </Routes>
  );
}

export default App;