import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../features/auth/authSlice";
import { Table } from "react-bootstrap";
import { BsPerson } from "react-icons/bs";
import { GoDatabase } from "react-icons/go";
import Select, { components, MenuListProps } from "react-select";
import { useGetUserDetailsQuery } from "../../features/users/usersApiSlice";
import { React, useState, useContext, useRef, useEffect } from "react";
import { LuEye } from "react-icons/lu";
import { MdVerified } from "react-icons/md";
import Avatar1 from '../../../src/assets/img/avatar-1.jpg'
import Avatar from '../../../src/assets/img/avatar.jpg'
import { useParams } from "react-router";
import { UTCToLocalDate } from "../../components/utils/utils";
import { MdDeleteOutline, MdClose } from "react-icons/md";
import Button from "react-bootstrap/Button";
import NewOrgansation from "../../components/NewOrgansation";

import "./styles.scss";

// import './styles.scss'

const MemberDetails = () => {
  const { userId } = useParams();
  const [hoverId, setHoverId] = useState(null)
  const {
    data: member_data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetUserDetailsQuery(
    { user_id: userId }
  );

  const [sortBy, setSortBy] = useState({
    label: "Name",
    value: "name"
  });
  const [sortByOptions, setSortByOptions] = useState([{
    label: "Name",
    value: "name"
  },
  {
    label: "Role",
    value: "role"
  }]);

  const setSortHandler = (event) => {
    setSortBy(event);
  };

  const tableStyle = {
    // border: "1px solid #ccc",
    borderRadius: "8px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const tableHeaderStyle = {
    backgroundColor: "#f8fafc",
    color: "white",
  };

  const rowStyle = {
    // backgroundColor: "rgba(87, 166, 250, 0.0705882353)",
    margin: "10px"
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: 'none'
  };

  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();

  const cardHoverStartHandler = (id) => {
    setHoverId(id)
  }
  const cardHoverEndHandler = () => {
    setHoverId(null)
  }

  return (
    <section className="layout-inner">
      <div className="member-detail-container-outer">
        <div className="heading-outer">
          <div className="back-outer"> User Details</div>
          {/* <div className="page-heading">{member_data && `${member_data.response_data.first_name} ${member_data.response_data.last_name}`}</div> */}
          <div className="deactivate-user"><button className="deactivate-btn"><MdClose /> Deactivate User</button></div>
          {/* <div>
            <button className="upload-members-btn upload-member-history-btn">History</button>
            <button className="upload-members-btn">Upload Members</button>
          </div> */}
        </div>
        {member_data &&
          <div className="member-detail-outer row">
            <div className="member-detail-inner-left">
              <div className="basic-detail-outer">
                <div className="user-image-outer">
                  <img className="user-image" src={member_data.response_data.user_profile.image} />
                </div>
                <div className="name">{member_data && `${member_data.response_data.first_name} ${member_data.response_data.last_name}`}</div>
                <div className="user-id">#{member_data && member_data.response_data.user_id}</div>
                <div className="basic-info">
                  <div className="heading"><BsPerson />Basic Information</div>
                  <div className="data-outer row">
                    <div className="label col-6">Email</div>
                    <div className="data col-6">{member_data && member_data.response_data.email}</div>
                  </div>
                  <hr />
                  <div className="data-outer row">
                    <div className="label col-6">D.O.B</div>
                    <div className="data col-6">{member_data && member_data.response_data.date_of_birth}</div>
                  </div>
                  <hr />
                  <div className="data-outer row">
                    <div className="label col-6">Gender</div>
                    <div className="data col-6">{member_data && member_data.response_data.user_profile.gender}</div>
                  </div>
                  <hr />
                  <div className="data-outer row">
                    <div className="label col-6">Phone Number</div>
                    <div className="data col-6">{member_data && member_data.response_data.phone_number}</div>
                  </div>
                  <hr />
                  <div className="data-outer row">
                    <div className="label col-6">Date Joined</div>
                    <div className="data col-6">{member_data && UTCToLocalDate(member_data.response_data.date_joined)}</div>
                  </div>
                  <hr />
                  <div className="data-outer row">
                    <div className="label col-6">Designation</div>
                    <div className="data col-6">{member_data && member_data.response_data.user_profile.designation}</div>
                  </div>
                </div>
              </div>

              <div className="profiles-outer">
                <div className="heading">Profiles</div>
                {member_data && member_data.response_data.profiles.map((profile) => {
                  return <div
                    class={!profile.clicked ? "card-outer" : "card-outer card-outer-clicked"}
                    // onMouse={() => { cardHoverStartHandler(profile.id) }}
                    onClick={() => {
                      if (hoverId === profile.id) {
                        cardHoverStartHandler(null)
                      } else {
                        cardHoverStartHandler(profile.id)
                      }
                    }
                  }
                    style={hoverId === profile.id ? { height: "450px", index: 111111 } : { height: "180px" }}
                  >
                    <div className="card-inner"
                    style={hoverId === profile.id ? { transform: "rotateY(180deg)" } : { }}
                    >
                      <div className="card-face"

                      >
                        <div class="card-face-image-inner">
                          <img className="profile-image" src={profile.image === null ? Avatar : profile.image} />
                        </div>
                        <div style={{ flex: "0.8" }}>
                          {/* <img src="path_to_image.jpg" alt="Profile Image" /> */}
                          {/* <div className="verified-outer">
                            <span
                              data-tooltip-id="user-verified"
                              data-tooltip-content="User Verified"
                              className="verified-badge"
                              style={profile.verified_by_user ? { color: "#9cff93" } : {}}
                            >
                              <MdVerified />
                            </span>
                            <span
                              data-tooltip-id="organisation-verified"
                              data-tooltip-content="Organisation Verified"
                              className="verified-badge"
                              style={profile.verified_by_organisation ? { color: "#78e46e" } : {}}
                            >
                              <MdVerified />
                            </span>
                            <span
                              data-tooltip-id="antro-verified"
                              data-tooltip-content="Antro Verified"
                              className="verified-badge"
                              style={profile.verified_by_antro ? { color: "#54a84c" } : {}}
                            >
                              <MdVerified />
                            </span>
                          </div> */}
                          <h2>{`${profile.name}`}</h2>
                          <p>Email: {profile.email} <span
                            data-tooltip-id="user-verified"
                            data-tooltip-content="User Verified"
                            className="verified-badge"
                            style={profile.email_verified ? { color: "rgb(53 207 39)" } : {}}
                          >
                            <MdVerified /></span></p>
                          <p>Phone: {profile.phone_number} <span
                            data-tooltip-id="user-verified"
                            data-tooltip-content="User Verified"
                            className="verified-badge"
                            style={profile.phone_verified ? { color: "rgb(53 207 39)" } : {}}
                          >
                            <MdVerified /></span></p>
                        </div>
                      </div>

                      <div className="card-face-back"
                        // onMouseEnter={() => { cardHoverStartHandler(profile.id) }}
                        // onMouseLeave={() => { cardHoverEndHandler(profile.id) }}
                        onClick={() => {
                          if (hoverId === profile.id) {
                            cardHoverStartHandler(null)
                          } else {
                            cardHoverStartHandler(profile.id)
                          }
                        }
                        }
                        style={hoverId === profile.id ? { maxHeight: "2000px", index: 111111 } : { maxHeight: "none", height: "0px" }}
                      >
                        <p>AntroId: {profile.antro_id}</p>
                        <div className="data-outer">
                          <div className="data-label">Designation</div>
                          <div className="data-input">{profile.designation}
                            <span
                              data-tooltip-id="user-verified"
                              data-tooltip-content="User Verified"
                              className="verified-badge"
                              style={profile.designation_verified ? { color: "rgb(53 207 39)" } : {}}
                            >
                              <MdVerified /></span></div>
                        </div>
                        <div className="data-outer">
                          <div className="data-label">Role</div>
                          <div className="data-input">{profile.role}<span
                            data-tooltip-id="user-verified"
                            data-tooltip-content="User Verified"
                            className="verified-badge"
                            style={profile.role_verified ? { color: "rgb(53 207 39)" } : {}}
                          >
                            <MdVerified /></span></div>
                        </div>
                        <div className="data-outer">
                          <div className="data-label">Experience</div>
                          <div className="data-input">{profile.experience}
                            <span
                              data-tooltip-id="user-verified"
                              data-tooltip-content="User Verified"
                              className="verified-badge"
                              style={profile.experience_verified ? { color: "rgb(53 207 39)" } : {}}
                            >
                              <MdVerified /></span></div>
                        </div>
                        <div className="data-outer">
                          <div className="data-label">Department</div>
                          <div className="data-input">{profile.department}
                            <span
                              data-tooltip-id="user-verified"
                              data-tooltip-content="User Verified"
                              className="verified-badge"
                              style={profile.department_verified ? { color: "rgb(53 207 39)" } : {}}
                            >
                              <MdVerified /></span></div>
                        </div>
                        <div className="data-outer">
                          <div className="data-label">Sub Department</div>
                          <div className="data-input">{profile.sub_department}
                            <span
                              data-tooltip-id="user-verified"
                              data-tooltip-content="User Verified"
                              className="verified-badge"
                              style={profile.sub_department_verified ? { color: "rgb(53 207 39)" } : {}}
                            >
                              <MdVerified /></span></div>
                        </div>
                        <div className="data-outer">
                          <div className="data-label">Website</div>
                          <div className="data-input">{profile.wesbite}</div>
                        </div>
                        <hr />
                        <div className="data-outer">
                          <div className="data-label">Address</div>
                          <div className="data-input">{profile.address}, {profile.city}, {profile.country}, {profile.postal_code}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                })}
              </div>
            </div>
            <div className="member-detail-inner-right">
              <div className="basic-info">
                <div className="heading"><GoDatabase />Other Information</div>
                <div className="data-outer row">
                  <div className="label col-6">contact_information</div>
                  <div className="data col-6">{member_data && member_data.response_data.phone_number}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Education</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.education}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Experience</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.experience}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Skills</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.skills}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">certifications</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.certifications}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Awards & Recognitions</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.awards_recognitions}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Personal Website</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.personal_website}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Conference Event</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.conference_event}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Languages</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.languages}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Projects</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.projects}</div>
                </div>
                <hr />
                <div className="data-outer row">
                  <div className="label col-6">Conference Events</div>
                  <div className="data col-6">{member_data && member_data.response_data.user_profile.conference_event}</div>
                </div>
              </div>
            </div>
          </div>}
      </div>
    </section>
  );
};

export default MemberDetails;
