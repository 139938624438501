import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useRemoveParticipantsMutation, useUpdateParticipantPermissionMutation } from "../../features/users/usersApiSlice";
import "./manage_channel.scss";

export const ManageChannelPopup = ({
  name,
  id,
  data,
  level,
  show,
  onSubmit,
  onRefresh,
  onClose,
}) => {
  const [channelName, setChannelName] = useState("");
  const [tempData, setTempData] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const user = useSelector(selectCurrentUser);

  const [removeParticipants, { isLoading: removeParticipantsIsLoading }] =
  useRemoveParticipantsMutation();
  const [updateParticipantPermission, { isLoading: updateParticipantPermissionIsLoading }] = 
  useUpdateParticipantPermissionMutation()

  const SubmitRemoveParticipantHandler = async (participant_id) => {
    console.log(data);
    const remove_data = {
      participants: [participant_id],
      group_id: id
    }

    const response = await removeParticipants(remove_data);
    console.log(response);
    await onRefresh({group_id: id})
    setTempData(data);
  };

  const updatePermissionsHandler = async (new_data) => {
    console.log(tempData, "ALKK");
    const permission_data = {
      data: new_data,
      group_id: id
    }

    const response = await updateParticipantPermission(permission_data);
    console.log(response);
    await onRefresh({group_id: id})
    setTempData(data);
  };


  useEffect(() => {
    if (show) {
      setTempData(data);
    }
  }, [show, data]);

  const arraysAreDifferent = (arr1, arr2) =>  {
    if (arr1.length !== arr2.length) {
        return true; // Different lengths, so they are definitely different
    }

    for (let i = 0; i < arr1.length; i++) {
        // Compare each element in the arrays
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
            return true; // Element at index i is different
        }
    }

    return false; // All elements are the same, so the arrays are the same
}

  const submitHandle = () => {
    onSubmit({ name: channelName, participants: selectedMembers });
    onClose();
    setSelectedMembers([]);
    setChannelName("");
  };

  const toggleItem = (event, itemId, type) => {
    if (type === "admin") {
      const updatedData = tempData.map((item) => {
        if (item.id === itemId) {
          // If the user_id matches, update the admin field
          if (event.target.checked) {
            return {
              ...item,
              admin: event.target.checked,
              sender: true,
            };
          } else {
            return {
              ...item,
              admin: event.target.checked,
            };
          }
        }
        return item;
      });
      console.log("sasfasg", updatedData);
      setTempData(updatedData);
      updatePermissionsHandler(updatedData)
    }
    if (type === "sender") {
      const updatedData = tempData.map((item) => {
        if (item.id === itemId) {
          // If the user_id matches, update the admin field
          return {
            ...item,
            sender: event.target.checked,
          };
        }
        return item;
      });
      setTempData(updatedData);
      updatePermissionsHandler(updatedData)
    }
    console.log(tempData, "KIAAA");
  };

  return (
    <div>
      <Popup
        modal
        className="manage-channel-popup"
        open={show}
        onClose={() => {
          onClose();
          setSelectedMembers([]);
          setChannelName("");
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
            setSelectedMembers([]);
            setChannelName("");
          }}
        >
          x
        </button>
        <div className="manage-channel-outer">
          <div className="popup-heading">Manage Channel</div>
          <div className="popup-content-inner">
            <div className="channel-name-outer">
              <div className="name-label">Name</div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#8ca6bc",
                }}
              >
                {name}
              </div>
            </div>
            <div className="member-select-outer">
              {/* <div className="member-select-label">
                Please update members to this channel
              </div> */}
              {/* <div className="members-list"> */}
              <table className="channel-details-table">
                <thead>
                  <tr>
                    <th>Participant</th>
                    <th>Admin</th>
                    <th>Sender</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tempData.map((member, index) => {
                    const adminCount = tempData.filter((m) => m.admin).length;
                    return <tr key={index}>
                      <td>
                        <div className="member-select-item-label">
                          {member.email}{" "}
                          {member.email === user.email && (
                            <span
                              style={{
                                background: "#5999fe",
                                padding: "2px 5px",
                                borderRadius: "7px",
                                color: "white",
                              }}
                            >
                              You
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {member.email !== user.email &&
                          <input
                            type="checkbox"
                            disabled={adminCount === 1 && member.admin}
                            className="member-select-checkbox"
                            value={member.admin}
                            checked={member.admin}
                            onChange={(event) =>
                              toggleItem(event, member.id, "admin")
                            }
                          />}
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="checkbox"
                            disabled={member.email === user.email}
                            className="member-select-checkbox"
                            value={member.sender}
                            checked={member.sender}
                            onChange={(event) =>
                              toggleItem(event, member.id, "sender")
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          {member.email !== user.email ? (
                            <button
                              className="action-btn"
                              onClick={() => {
                                SubmitRemoveParticipantHandler(member.id)
                              }}
                            >Remove</button>
                          ) : (
                            <button
                              disabled={adminCount === 1 && member.admin}
                              className={(adminCount === 1 && member.admin)?"action-btn action-btn-disabled": "action-btn"}
                            >
                              Exit
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
})}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="popup-content-functionality-btn">
            <button
              className={
                arraysAreDifferent(data, tempData)
                  ? "submit-btn"
                  : "submit-btn submit-btn-disabled"
              }
              onClick={submitHandle}
            >
              Update
            </button>
          </div> */}
        </div>
      </Popup>
    </div>
  );
};
