import { useEffect, useRef } from 'react';
import { SOCKET_URL_CHAT } from '../../settings';

function useWebSocket(chatUrl) {
  const socketRef = useRef(null);
  const callbacks = useRef({});
  const reconnectTimeout = useRef(null);
  const isSocketOpen = useRef(false);
  const messageQueue = useRef([]);
  
  useEffect(() => {
    const path = `${SOCKET_URL_CHAT}/ws/broadcast/${chatUrl}/`;

    const connectSocket = () => {
      socketRef.current = new WebSocket(path);
      socketRef.current.onopen = () => {
        // console.log('WebSocket open');
        // Clear any pending reconnect attempts
        clearTimeout(reconnectTimeout.current);
        isSocketOpen.current = true
        while (messageQueue.current.length > 0) {
          const queuedMessage = messageQueue.current.shift();
          sendMessage(queuedMessage);
        }
      };
      socketRef.current.onmessage = (e) => {
        socketNewMessage(e.data);
      };
      socketRef.current.onerror = (e) => {
        console.log(e);
        console.log(e.message);
      };
      socketRef.current.onclose = () => {
        // console.log("WebSocket closed, attempting to reconnect...");
        isSocketOpen.current = false;
        // Set a timeout to attempt to reconnect after a delay
        reconnectTimeout.current = setTimeout(connectSocket, 3000); // Retry every 3 seconds
      };
    };

    // Initial connection
    connectSocket();

    // Cleanup when the component unmounts
    return () => {
      socketRef.current.close();
      // Clear any pending reconnect attempts
      clearTimeout(reconnectTimeout.current);
    };
  }, [chatUrl]);

  const socketNewMessage = (data) => {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    if (Object.keys(callbacks.current).length === 0) {
      return;
    }
    if (callbacks.current['new_message'] === undefined) {
      return;
    }
    if (command === 'messages') {
      callbacks.current[command](parsedData.messages);
    }
    if (command === 'details') {
      callbacks.current[command](parsedData.data);
    }
    if (command === 'new_message') {
      console.log(callbacks.current, "AMAR");
      callbacks.current[command](parsedData.message);
    }
  };

  const fetchMessages = (email, chatId) => {
    sendMessage({
      command: 'fetch_messages',
      email: email,
      chatId: chatId,
    });
  };
  const fetchDetails = (email, chatId) => {
    sendMessage({
      command: 'fetch_details',
      email: email,
      chatId: chatId,
    });
  };

  const newChatMessage = (message) => {
    sendMessage({
      command: 'new_message',
      from: message.from,
      message: message.content,
      chatId: message.chatId,
    });
  };

  const addCallbacks = (messagesCallback, detailsCallback, newMessageCallback) => {
    callbacks.current['messages'] = messagesCallback;
    callbacks.current['details'] = detailsCallback;
    callbacks.current['new_message'] = newMessageCallback;
  };

  const sendMessage = (data) => {
    try {
      if (socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ ...data }));
      } else {
        messageQueue.current.push(data);
      }
    } catch (err) {
    }
  };

  const getState = () => {
    return socketRef.current.readyState;
  };

  return {
    fetchMessages,
    fetchDetails,
    newChatMessage,
    addCallbacks,
    getState,
  };
}

export default useWebSocket;
