import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select, { components, MenuListProps } from "react-select";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useGetDocumentCategoryQuery, useCreateUploadDocumentMutation } from "../../features/users/usersApiSlice";
import "./add_document.scss";

export const AddDocumentPopup = ({show, onSubmit, onClose }) => {

  const {
    data: document_categories,
    isLoading: isLoading,
    isSuccess: isSuccess,
    isError: isError,
    error: error,
    refetch,
  } = useGetDocumentCategoryQuery();

  const [createUploadDocument, { isLoading: createUploadDocumentIsLoading }] =
  useCreateUploadDocumentMutation();

  const user = useSelector(selectCurrentUser);
  const [category, setCategory] = useState({label: "Select Category", value: null});
  const [categories, setCategories] = useState([])
  const [documentFile, setDocumentFile] = useState();

  const submitHandle = async () => {
    const formData = new FormData();
    formData.append("file", documentFile);
    formData.append("category_id", category.value);
    const response = await createUploadDocument(formData);
    console.log(response);
    onSubmit({});
    onClose();
  };

  const handleDocumentFile = (event) => {
    setDocumentFile(event.target.files[0]);
  };


  const setDocumentCategoryHandler = (event) => {
    // console.log(event);
    setCategory(event);
  }
  const reloadCategories = async () => {
    const data = await refetch()
    const abc = data.data.data.map((item)=> {
      return {label: item.name, value: item.id}
    })
    setCategories([{label : "Select Category", value: null}, ...abc])
  }

  useEffect(()=> {
    reloadCategories()
    setCategory({label: "Select Category", value: null})
   
  }, [show])


  return (
    <div>
      <Popup
        modal
        className="add-document-popup"
        open={show}
        onClose={() => {
          onClose();
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
          }}
        >
          x
        </button>
        <div className="add-channel-outer">
          <div className="popup-heading">Add Document</div>
          <div className="popup-content-inner">
            <div className="channel-name-outer">
              <div className="name-label">
                Please select a category
              </div>
              <Select
                value={category}
                onChange={setDocumentCategoryHandler}
                options={categories}
              />
              {/* <input
                className="channel-name-input"
                onChange={handleChannelnNameInput}
                value={channelName}
                required
              /> */}
            </div>
            <div className="member-select-outer">
              <div className="member-select-label">
                Please upload a file
              </div>
              <input
                type="file"
                id="document"
                accept=".png,.jpg,.jpeg,.doc,.pdf"
                onChange={handleDocumentFile}
              />
            </div>
          </div>
          <div className="popup-content-functionality-btn">
            <button
              className={
                documentFile && category.value !== null
                  ? "submit-btn"
                  : "submit-btn submit-btn-disabled"
              }
              onClick={submitHandle}
            >
              Submit
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
