import { useNavigate, Link } from "react-router-dom";
import { React, useState, useContext, useRef, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import banner4 from '../../../src/assets/img/banner-4.png'
import ServiceBg from '../../../src/assets/img/service-bg.svg'
import MobileImage from '../../../src/assets/img/mobile.png'
import ObjectsImage from '../../../src/assets/img/objects.svg'
import Testimonialsmage from '../../../src/assets/img/testimonials-bg.png'
import Avatar1 from '../../../src/assets/img/avatar-1.jpg'
import webBackground from '../../../src/assets/img/website-background.jpg'
import Section1_bg from "../../../src/assets/img/landing-section-1-bg.png"
import Avatar2 from '../../../src/assets/img/avatar-2.png'
import { FaQuoteLeft } from "react-icons/fa6";
import Frame from '../../../src/assets/img/Frame.png'
import Mobile from '../../../src/assets/img/Mobile .png'
import { BsDot } from "react-icons/bs";
import { FaGooglePlay } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Authentication from '../../../src/assets/img/Authentication.png'
import { LuDownload } from "react-icons/lu";
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { IoMdStar } from "react-icons/io";
import { BsStars } from "react-icons/bs";



import './styles.scss'

const Landing = () => {

  const navigate = useNavigate();

  return (

    <section className="home-page-layout-inner">
      <div className="section-1">
        <div className="section-left">
          <div className="heading-1">
            <span><BsDot /></span>connect with confidence</div>
          <div className="heading-2">Unlock Your Social
            <div> Potential:
              <span style={{ color: "#4CAF50" }}> Get Verified,</span>
            </div>
            <div>
              <span style={{ color: "#2196F3" }}> Get Connected!</span>
            </div>
          </div>
          <div className="heading-3">Empower your social journey with verified connections.</div>
          <div className="section-1-btns-outer">
            <button className="download-btn">
              <div className="btn-image">
                <FaGooglePlay />
              </div>
              <div className="button-text-outer">
                <div className="text-1">Get it on</div>
                <div className="text-2">Google Play</div>
              </div>

            </button>
            <button className="download-btn" style={{ marginLeft: "20px" }}>
              <div className="btn-image">
                <FaApple />
              </div>
              <div className="button-text-outer">
                <div className="text-1">Get it on</div>
                <div className="text-2">App Store</div>
              </div>

            </button>
          </div>
        </div>
        <div className="section-right">
          <img className="section-1-image" src={Frame} />
        </div>
      </div>
      <div className="section-2">
        <div className="section-2-inner">
          <div className="section-2-left">
            <img src={Mobile} />
          </div>
          <div className="section-2-right">
            <div className="main-heading">-Features-</div>
            <div className="heading-2">Providing Some Great Features For You</div>
            <div className="sub-heading-detail">
            <div className="sub-text-outer">
              <div className="icon-outer"><IoIosCheckmarkCircleOutline /></div>
              <div>Robust Authentication mechanism ensure secure access for admins and corporate users.</div>
              </div>
            <div className="sub-text-outer"><div className="icon-outer"><IoIosCheckmarkCircleOutline /></div><div>Meet like minded people around you.</div> </div>
            <div className="sub-text-outer"><div className="icon-outer"><IoIosCheckmarkCircleOutline /></div><div>schedule a meeting from anywhere and join at your own ease.</div></div>
            <div className="sub-text-outer"><div className="icon-outer"><IoIosCheckmarkCircleOutline /></div><div>Go beyond likes and start meaningfull conversations with direct chat.</div></div>
            <div className="sub-text-outer"><div className="icon-outer"><IoIosCheckmarkCircleOutline /></div><div>Fluat yourself with true you,as we provide true identity of person.</div></div>
            </div>
            <button className="features-btn">See More Features</button>
          </div>
        </div>

      </div>
      <div className="section-3">
      <div className="section-3-left"> 
        <div className="main-1-heading">-How it Works-</div>
        <div className="heading-3">Unlock the power of our platform in just 3 simple steps:</div>
        <div className="steps-outer">
          <div className="step">
            <div className="step-inner">
              <div className="line1"><span className="inner-icon"><LuDownload /></span><span>Download App</span></div>
              <div className="line2">Get started by downloading our app to access all features on your device.</div>
            </div>
            <div className="count-circle">1</div>
          </div>
          <div className="step">
            <div className="step-inner">
              <div className="line1"><span><MdOutlineManageAccounts /></span><span>Create Account</span></div>
              <div className="line2">Create your personalized account in just a few simple steps.</div>
              <div className="count-circle" style={{top: "50%"}}>2</div>
            </div>
          </div>
          <div className="step">
            <div className="step-inner">
              <div className="line1"><span><MdOutlineVerified /></span><span>Instant Verification</span></div>
              <div className="line2">Instantly verify your account for enhanced security and seamless access.</div>
              <div className="count-circle" style={{top: "60%"}}>3</div>
            </div>
          </div>

        </div>
        <div className="download-btn-outer">
          <button className="download-btn">Download Now</button>
        </div>
        </div>
        <div className="section-3-right">
          <img src={Authentication} />
        </div>
      </div>
      <div className="section-4">
        <div className="section-4-inner">
          <div className="heading1">-Testimonials-</div>
          <div className="main-heading2">
          <div className="heading2">Read from our satisfied<span>Clients</span></div>
          <div className="heading-outer-span"><span><BsStars /></span></div>
          </div>
          <div className="heading3">Discover the experiences and feedback shared by our valued clients.</div>
          <div className="testimonial-list-outer">
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                  <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-list-outer">
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
            <div className="individual-testimonial">
              <div className="testimonial-quotation"><FaQuoteLeft /></div>
              <div className="testimonial-text">Using this platform has revolutionalized way i managed my projects. the intuitive interface and robust features have save me countless our of work!</div>
              <div className="testimonial-footer">
                <div className="testimonial-image-outer">
                <img className="testimonial-avatar" src={Avatar2} />
                </div>
                <div className="details">
                  <div className="name">Emily johson</div>
                  <div className="designation">Marketing Manager</div>
                </div>
                <div className="star-outer">
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                  <span><IoMdStar /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      

    </section>
  )
}

export default Landing
