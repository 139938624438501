import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api/apiSlice"
import authReducer from '../features/auth/authSlice'
import storage from 'redux-persist/lib/storage' 
import { persistReducer } from "redux-persist"
import { combineReducers } from "@reduxjs/toolkit"
import channelSlice from "../features/channels/channelSlice"

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
  }

const reducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        channel: channelSlice
})

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})